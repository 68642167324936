import React, { useState } from "react";
import EnhancedLearning from "./Component/EnhancedLearning";
import Unleash from "./Component/Unleash";
import Banner from "@/components/LandingPage/Common/Banner";
import Worldwide from "./Component/WorldWide";
import Learning from "@/components/LandingPage/Common/Learning";
import LokiTabs from "@/components/LandingPage/Common/LokiTabs";
import CollaborativeDebates from "./Component/CollaborativeDebates";
import { useTranslation } from "react-i18next";
import Modal from "@/components/Modal";
import TextToSpeechModal from "@/components/Modal/TextToSpeechModal";
import CollaborativeDebateModal from "@/components/Modal/CollaborativeDebateModal";
import ProPlan from "./Component/ProPlan";
import EmpowerProductivity from "./Component/EmpowerProductivity";
import TextToImageModal from "@/components/Modal/TextToImageModal";
import FreeTrialModal from "@/components/Modal/FreeTrialModal";
import DiscoverModal from "@/components/Modal/DiscoverModal";
import { useColorMode } from "@chakra-ui/color-mode";
import Head from "next/head";
import { Illustration } from "modules/FeaturesModule/Component/Illustration";
import Questions from "modules/FeaturesModule/Component/Questions";
import FeatureData from "@/mocks/guestFeature";
import UseCase from "./Component/UseCase";
import WhyLoki from "./Component/WhyLoki";
import VideoPlayer from "modules/FeaturesModule/Component/VideoPlayer";
import Select from "@/components/Select";
const HomeModule = () => {
  const { t } = useTranslation("translation");
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleCollaborate, setVisibleCollaborate] = useState<boolean>(false);
  const [visibleTextToImage, setVisibleTextToImage] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";
  const { mainquestionAnswer } = FeatureData();

  const schemaBreadcrumb = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Loki: Advanced AI Tools for Productivity",
        item: "https://lokichat.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "AI Tools",
        item: "https://lokichat.com/features",
      },
      // {
      //   "@type": "ListItem",
      //   position: 3,
      //   name: "How it Works",
      //   item: "https://lokichat.com/how-it-works",
      // },
      {
        "@type": "ListItem",
        position: 4,
        name: "App Download",
        item: "https://lokichat.com/download",
      },
      {
        "@type": "ListItem",
        position: 5,
        name: "Pricing",
        item: "https://lokichat.com/pricing-home",
      },
    ],
  };

  const schemaProduct = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: "Loki: AI Tools",
    url: "https://lokichat.com/",
    image: "https://lokichatdev.blob.core.windows.net/images/loki/logo.svg",
    description:
      "Enhance productivity with Loki AI tools like Debate, Prompt Advisor, Copywriter, Translation, Transcription, Summarization, Text-to-Speech, Text-to-Image, and Goal Management.",
    brand: {
      "@type": "Brand",
      name: "Loki",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.9",
      reviewCount: "200",
    },
    offers: {
      "@type": "Offer",
      priceCurrency: "EUR",
      price: "10",
      priceValidUntil: "2025-12-31",
      itemCondition: "New",
      availability: "InStock",
      hasMerchantReturnPolicy: {
        "@type": "MerchantReturnPolicy",
        refundType: "NoRefunds",
        returnPolicyCategory: "Unopened",
        restockingFee: "NoRestockingFee",
      },
      shippingDetails: {
        "@type": "OfferShippingDetails",
        shippingRate: {
          "@type": "MonetaryAmount",
          currency: "EUR",
          value: "0.00",
        },
        shippingDestination: {
          "@type": "DefinedRegion",
          addressRegion: "Worldwide",
        },
        estimatedDeliveryTime: {
          "@type": "QuantitativeValue",
          value: "Instant",
          unitText: "Delivery",
        },
      },
    },
  };

  const schemaRating = {
    "@context": "https://lokichat.com/",
    "@type": "Product",
    name: "Loki: Best Productivity AI Tools Online",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      ratingCount: "45",
      reviewCount: "52",
    },
  };

  return (
    <div>
      {/* <div className="max-w-[800px] m-auto -mt-3">
        <div className=" max-w-[250px]">
          <Select
            classButton="shadow-none bg-transparent rounded-t-lg rounded-b-none ui-open:shadow-none dark:bg-n-5 dark:shadow-none dark:text-n-6"
            title={"Select Feature"}
            items={widgets}
            value={widget}
            fromHome={true}
            onChange={setWidget}
            // PreIcon={() => <VoiceIcon />}
            arrowColor={"black"}
          />
        </div>
      </div> */}
      <Head>
        <title>Loki</title>
        <meta
          name="description"
          content="Loki: Best Productivity AI Tools Online"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaBreadcrumb) }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaProduct) }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaRating) }}
        />
      </Head>
      <Banner />
      
    </div>
  );
};

export default HomeModule;
